'use client';

import type { FC, ReactNode } from 'react';
import React, { useMemo, useRef } from 'react';
import type { UserInfoInterface } from '../types/user';
import { useLiveChat } from '~hooks/use-live-chat';
import { LiveChatWidget as LiveChatWidgetComponent } from '@livechat/widget-react';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import { LiveChatInterface } from '../types/live-chat';

interface LiveChatProviderProps {
  children: ReactNode;
  userInfo: UserInfoInterface | undefined;
  locale: string;
}

const customStyle = {
  visibility: {
    desktop: {
      xOffset: 15,
      yOffset: 15,
      position: 'br',
    },
    mobile: {
      xOffset: 0,
      yOffset: 200,
      position: 'cr',
    },
  },
};

interface ChatWidgetProp {
  userInfo: UserInfoInterface | undefined;
  livechat: LiveChatInterface;
}

const LiveChatWidget: FC<ChatWidgetProp> = (props) => {
  const { userInfo, livechat } = props;
  const handleNewEvent = (_: any): void => {
  };
  const extractDirectChatLink = (
    directChatLink: string,
  ): {
    license: string;
    group: string;
  } => {
    const segments = directChatLink?.split('/');
    let license = '';
    let group: string | undefined;
    if (segments.length === 5) {
      license = segments.at(-2) || '';
      group = segments.at(-1);
    } else if (segments.length === 4) {
      license = segments.at(-1) || '';
    }
    return {
      license,
      group: group || '',
    };
  };
  const { license, group } = extractDirectChatLink(livechat.directChatLink);
  const chatProps = Object.assign(
    {
      group,
      license,
    },
    livechat?.metadata || {},
  );
  return (
    <div
      style={{
        position: 'fixed',
        bottom: 10,
        right: 0,
        zIndex: 9999,
      }}
    >
      <LiveChatWidgetComponent
        customerName={userInfo?.username}
        onNewEvent={handleNewEvent}
        visibility="minimized"
        {...chatProps}
      />
    </div>
  );
};

const TawkChatWidget: FC<ChatWidgetProp> = (props) => {
  const { userInfo, livechat } = props;
  const tawkMessengerRef = useRef<any>();
  const onLoad = (): void => {
    if (!tawkMessengerRef.current && !userInfo) {
      return;
    }
    const attribute = {
      name: userInfo?.username,
      hash: userInfo?._id,
      store: `${userInfo?.agent}/${userInfo?.username}`,
      phone: userInfo?.phoneNumber || '-',
    };
    tawkMessengerRef.current.setAttributes(attribute, (error) => {
      if (error) {
        console.error('setAttributes error', error);
      }
    });
  };
  return (
    <TawkMessengerReact
      customStyle={customStyle}
      onLoad={onLoad}
      propertyId={livechat.objectId}
      ref={tawkMessengerRef}
      widgetId={livechat.agent}
    />
  );
};

const LiveChatProvider: FC<LiveChatProviderProps> = ({
  children,
  userInfo,
  locale,
}) => {
  const { data: liveChatInfo } = useLiveChat();
  const livechatComponent = useMemo(() => {
    if (!liveChatInfo) {
      return;
    }
    const livechat = liveChatInfo
      .filter((chat) => chat.language === locale)
      .at(0);
    if (!livechat) {
      return;
    }
    if (livechat.provider === 'livechat') {
      return <LiveChatWidget livechat={livechat} userInfo={userInfo} />;
    }
    if (livechat.provider === 'tawkto') {
      return <TawkChatWidget livechat={livechat} userInfo={userInfo} />;
    }
  }, [liveChatInfo]);

  return (
    <>
      {children}
      {livechatComponent}
    </>
  );
};

export default LiveChatProvider;
