'use client';

import type { FC, PropsWithChildren } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import type { UserInfoInterface } from 'types/user';
import LiveChatProvider from '~contexts/live-chat-provider';

const LayoutClient: FC<
  PropsWithChildren & {
    userInfo: UserInfoInterface | undefined;
    locale: string;
  }
> = ({ children, userInfo, locale }) => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITEKEY!}
    >
      <LiveChatProvider locale={locale} userInfo={userInfo}>
        <div className="mx-auto w-full max-w-[1920px]">{children}</div>
      </LiveChatProvider>
    </GoogleReCaptchaProvider>
  );
};

export default LayoutClient;
