import { EnumActionNotification } from '~constants/etc';

export interface NotificationRearrangeInterface {
  id?: string;
  index?: string;
  key?: string;
  action: string;
  isSocket: boolean;
  username?: string;
  data: {
    amount: number;
    wallet?: string;
    sequence?: number;
    status?: string;
  };
}

const getNotificationRearrange = (
  notifyInfo,
): NotificationRearrangeInterface[] => {
  switch (notifyInfo.action) {
    case EnumActionNotification.DepositCancelled:
    case EnumActionNotification.WithdrawalCancelled:
      return [
        {
          action: notifyInfo.action,
          isSocket: true,
          data: {
            amount: notifyInfo.data.amount,
            sequence: notifyInfo.data.sequence,
          },
        },
      ];
    case EnumActionNotification.DepositSuccess:
      if (notifyInfo.data.wallets.length > 1) {
        return notifyInfo.data.wallets.map((res) => ({
          action: notifyInfo.action,
          isSocket: true,
          data: { amount: res.amount, wallet: res.walletType },
        }));
      }
      return [
        {
          action: notifyInfo.action,
          isSocket: true,
          data: {
            amount: notifyInfo.data.amount,
            wallet: notifyInfo.data.wallets[0].walletType,
          },
        },
      ];
    case EnumActionNotification.WithdrawalSuccess:
      return [
        {
          action: notifyInfo.action,
          isSocket: true,
          data: {
            amount: notifyInfo.data.amount,
            wallet: notifyInfo.data.wallets[0].walletType,
          },
        },
      ];
    default:
      return [
        {
          key: 'globals',
          action: `${notifyInfo.action}-${notifyInfo.data.status}`,
          isSocket: true,
          username: notifyInfo.username,
          data: {
            amount: notifyInfo.data.amount,
          },
        },
      ];
  }
};

export default getNotificationRearrange;
